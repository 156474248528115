html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
body {
    background-color: #000c0a;
    color: #fff;
    font-family: 'Manrope', sans-serif;
}
button {
    border: 8px solid #1EF4C0;
    border-radius: 40px;
    padding: 10px 50px;
    background: #ff10;
    color: #1EF5C0;
    font-size: 18px;
    text-transform: capitalize;
    box-shadow: 0px 0px 22px #1EF5C0;
    animation: pulse 2s infinite;
}
h1,h2,h3,h4,h5,h6,p {
    font-family: 'Manrope', sans-serif;
}
h1 {
    font-weight: bold;
    font-size: 58px;
    line-height: 68px;
}
h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
}
h3 {
    font-size: 20px;
    line-height: 30px;
}

/* NAVIGATIONS */
section#banner h1 em, section#aboutus h1 {
  font-style: normal;
  color: #ff10;
  -webkit-text-stroke: 2px #1ef4c0;
  text-stroke: 2px white !important;
  background: #ff10;
}
.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
    line-height: 23px;
}
.header .navbar a {
    color: #fff !important;
    font-family: 'Manrope', sans-serif;
}
.navbar-nav.navbar-nav-scroll {
    justify-content: end;
    gap: 30px;
    width: 100%;
}
.header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px 0;
  z-index: 111111;
}
nav.navbar.navbar-expand-lg.navbar-light a.dropdown-item {
    color: #000 !important;
    font-size: 12px;
    line-height: 23px;
}

/* BANNERS */
section#banner h1.animated-text span {
    display: table;
}
section#banner .first-row {
	position: relative;
}

section#banner h1.animated-text span {
	color: #fff;
	font-size: 126px;
	position: absolute;
	
}
section#banner video {
    position: fixed;
    height: 100% !important;
    top: 0;
    z-index: -1;
    bottom: 0;
     /* transform: rotate(-180deg); */
}
section#banner h1.animated-text span:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px #1ef4c0;
}

section#banner h1.animated-text span:nth-child(2) {
	color: #1EF5C0;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}


section#banner {
    padding: 200px 0 0px 0;
}
section#banner h1 {
    font-size: 128px;
    line-height: 138px;
    font-family: 'Manrope';
    color: #E1E1E1;
}

  section#banner button, section#featurework button, section#footer button, #aboutus button
 {
    animation: pulse 2s infinite;
}
section#banner h1.animated-text button {
    padding: 0px 40px;
    line-height: 70px;
    position: relative;
    top: -39px;
    animation: pulse 2s infinite;
    border-radius: 50px !important;
}
section#banner:after {
    content: '';
    background: #00000040;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
}
@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 #1EF5C0;
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px #1EF5C0;
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 #1EF5C0;
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 #1EF5C0;
      box-shadow: 0 0 0 0 #1EF5C0;
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  
  section#banner .second-row h2 {
    font-size: 58px;
    line-height: 68px;
    color: #E1E1E1;
}
section#banner .second-row {
    padding: 200px 0px 100px 0px;
}

/* Our Projects  */
section#projects img:hover {
    opacity: 0.3;
}

section#projects img {
    margin: 30px;
    transition: .9s;
    filter: brightness(0)invert(1);
}
section#projects h1 {
    font-size: 45px;
    line-height: 55px;
    font-weight: 900;
    text-transform: capitalize;
}
section#projects {
    text-align: center;
    padding: 50px 0;
}

section#projects h1 span {
    color: #ff10;
    -webkit-text-stroke: 2px #1ef4c0;
    text-stroke: 2px white !important;
    background: #ff10;
}

/* FEATURE WORK */
section#featurework h1 span {
    color: #ff10;
    -webkit-text-stroke: 2px #1ef4c0;
    text-stroke: 2px white !important;
    background: #ff10;
}
section#featurework img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
}
section#featurework .image-hover .content {
    position: absolute;
    top: 100px;
    right: 100px;
    background: #1ef4c09c;
    padding: 60px 30px;
    border-radius: 0px 20px 20px 0px;
    opacity: 0;
}
section#featurework .image-hover {
    position: relative;
    transition: .9s;
}

section#featurework .image-hover:hover .content {
    opacity: 1;
    transition: .9s;
    top: 160px;
}
section#featurework .image-hover:hover img {
    animation: pulse 2s infinite;
}
section#featurework .image-hover:hover img {
    transition: .9s;
    opacity: 0.2;
}

/* CARD Carousel css */
 /* no circle */
 section#ourteams .flickity-button {
  background: transparent;
}
section#ourteams .flickity-slider {
  left: -300px;
}
section#ourteams .flickity-prev-next-button {
  width: 60px;
  height: 60px;
}
section#ourteams .flickity-button:disabled {
  display: none;
}
section#ourteams .flickity-prev-next-button .flickity-button-icon {
  display: none;
}
section#ourteams .flickity-prev-next-button {
  top: 51%;
  padding: 0 0 !important;
  box-shadow: unset;
  font-size: 12px !important;
  background-image: url(../Images/left.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
section#ourteams button.next {
  background-image: url(../Images/right.png);
  right: 56%;
}
section#ourteams button.previous {
  left: 17%;
}
section#ourteams .carousel {
  margin: auto;
  max-width: 100% !important;
  height: auto;
  border-radius: 20%;
}
section#ourteams .carousel-cell {
  margin-right: 35px;
  float: right;
  max-width: 253px;
  width: 100%;
  height: auto;
  border-radius: 15px;
  counter-increment: carousel-cell;
  transition: all .3s ease;
  opacity: 0.3;
  background: #0b1e1c;
  filter: blur(6px);
  padding: 32px 10px;
}
section#ourteams .carousel-cell.is-selected {
  filter: blur(0);
  opacity: 1;
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #0b1e1c;
  border: 1px solid #20FFC9F0;
}
    
    /* name */
section#ourteams .carousel-cell .n {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  color:black;
  counter-increment: carousel-cell;
}
    
    /* button */
 section#ourteams .carousel-cell .b {
  cursor: pointer;
  margin: auto;
  width: 180px;
  padding: 1px;
  background: green;
  border: 1px solid green;
  border-radius: 15px;
  font-size: 15px;
  color: white;
  text-align: center;
  counter-increment: carousel-cell;
  transition: all .3s ease;
}

 /* picture */
 section#ourteams .carousel-cell .p {
  border-radius: 15px;
  width: auto;
  height: 140px;
  counter-increment: carousel-cell;
}
section#ourteams .carousel-cell {
  text-align: center;
}
section#ourteams .carousel-cell .b:hover {
  background: #00e509;
  transform: scale(1.03);
  font-size: 16px;
}
    
section#ourteams .carousel-cell .b:active {
  background: white;
  color: green;
  transform: scale(0.9);
  font-size: 16px;
}
    
section#ourteams .carousel-cell .b-selected {
  cursor: pointer;
  margin: auto;
  width: 180px;
  padding: 1px;
  background: white;
  border: 1px solid green;
  border-radius: 15px;
  font-size: 15px;
  color: green;
  text-align: center;
  counter-increment: carousel-cell;
  transition: all .3s ease;
}
section#ourteams .carousel-cell .sociallinks ul li {
  list-style: none;
}

section#ourteams .carousel-cell .sociallinks ul li img {
  width: 24px;
}
section#ourteams .carousel-cell .sociallinks ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}
section#ourteams {
  background: #1E3C3924;
  padding: 50px 0;
}
section#ourteams .heading-text {
  position: absolute;
  right: 90px;
  width: 41%;
  top: 79px;
}

section#ourteams .heading-text h1 span {
  color: #ff10;
  -webkit-text-stroke: 2px #1ef4c0;
  text-stroke: 2px white !important;
  background: #ff10;
}

section#ourteams {
  position: relative;
}

section#ourteams .heading-text h5 {
  font-size: 18px;
  line-height: 28px;
}

section#ourteams .heading-text p {
  font-size: 17px;
  line-height: 29px;
  margin-bottom: 5px;
}
section#ourteams .heading-text h1 {
  margin: 0;
  font-weight: 900;
}
section#ourteams .heading-text h6 a {
  color: #fff;
  text-decoration: unset;
}
section#ourteams .heading-text h6 {
  font-size: 20px;
  line-height: 30px;
}

/* Product */
#products-ideas h3 {
  font-size: 37px;
  line-height: 47px;
}

#products-ideas h1 {
  color: #ff10;
  -webkit-text-stroke: 2px #1ef4c0;
  text-stroke: 2px white !important;
  background: #ff10;
  text-transform: uppercase;
  font-size: 47px;
  line-height: 58px;
}
section#products-ideas {
  padding: 50px 0px;
}
section#products-ideas h1, section#products-ideas h3 {
  text-align: center;
  margin: 0;
}
section#products-ideas .products img {
  margin-bottom: 70px;
}
section#products-ideas .products:hover {
  transition: .9s;
}
section#products-ideas .seo {
  background-image: url(../Images/seobg.png) !important;
}
section#products-ideas .col-6:hover .logo {
  background-image: url(../Images/logobg.png) !important;
  transition: .9s;
}
section#products-ideas .col-6:hover .web {
  background-image: url(../Images/webbg.png) !important;
  transition: .9s;
}
section#products-ideas .col-6:hover .uiux {
  background-image: url(../Images/uiux.png) !important;
  transition: .9s;
}
section#products-ideas .products {
  background: #111111;
  padding: 50px 40px;
  margin: 0 auto;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Footer */
section#footer button {
  float: right;
}
section#footer .call-to-action {
  border-bottom: 2px solid #A1A1A1;
  padding-bottom: 21px;
}
section#footer .footer-linkss {
  padding: 20px 0px;
}
.about .social ul li {
  list-style: none;
}

.about .social ul li img {
  width: 30px;
}
.about .social ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}
.footer-nav-links ul li a {
  color: #fff;
  text-decoration: unset;
  font-size: 14px;
  line-height: 24px;
}

.footer-nav-links ul li {
  list-style: none;
  margin-bottom: 14px;
}
.footer-nav-links ul {
  margin: 0;
  padding: 0;
}
.footer-nav-links li span {
  width: 70%;
  float: right;
}
.footer-nav-links li em {
  width: 30%;
  float: left;
  font-style: normal;
}

.footer-nav-links li {
  font-size: 14px;
  line-height: 24px;
}
.about p {
  font-size: 14px;
  line-height: 24px;
}

/* Ideas bring */
section#discover h1 {
  text-transform: uppercase;
}
section#bringideas .headings p {
  padding: 0;
  margin: 0;
}
section#bringideas .tabs ul li {
  color: #fff;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}

section#bringideas .tabs ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
section#bringideas .tabs ul li.active {
  color: #4EAB9B;
  font-weight: bold;
  border-color: #4EAB9B;
}
section#bringideas {
  padding: 50px 0px;
  background: #000c0a;
}
section#bringideas .tabs-componentbutton button {
  padding: 10px 18px;
  border: 2px solid;
  animation: pulse 2s infinite;
}

section#bringideas .tabs-componentbutton {
  display: flex;
  justify-content: center;
  gap: 20px;
}
section#bringideas .tab-content {
  width: 80%;
  margin: 0px auto;
  padding-top: 30px;
}
.calltoactions a {
  color: #68e7c8;
  text-decoration: unset;
  border-bottom: 1px solid;
  font-family: sans-serif;
}
section#bringideas .col-5 .headings h1 {
  text-transform: uppercase;
}
section#discover h1 span {
  font-weight: 100;
  color: #1ef4c0;
}
section#bringideas .col-5 .headings h1 span {
  color: #ff10;
  -webkit-text-stroke: 2px white;
  text-stroke: 2px white !important;
  background: #ff10;
}
.calltoactions {
  display: flex;
  gap: 23px;
}

/* MEDIA QUERY */

@media (max-width: 1600px) {}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {}

@media (max-width: 1280px) {
  section#jobpositions .row .col h2 {
    font-size: 23px;
    line-height: 33px;
}
}

@media (max-width: 1024px) {}

@media (max-width: 800px) {}

@media (max-width: 768px) {}

@media (max-width: 480px) {
  .details-popup .details-content {
    width: 98% !important;
    top: 1% !important;
    padding: 12px 10px !important;
}
  section#jobpositions .row .col {
    width: 100%;
}
  section#logo-contact-form .contact-inner form textarea {
    height: 70px;
    resize: unset;
}
  section#logo-contact-form .contact-inner form input, section#logo-contact-form .contact-inner form textarea {
    padding: 6px 20px;
}
  section#logo-contact-form .about-banner-buttons ul li {
    list-style: none;
}
  .about-banner-buttons.animate__animated.animate__bounce {
    padding: 0px 0px 20px 0px !important;
}
  section#logo-contact-form .contact-inner .col-6 {
    padding: 0px !important;
}
section#logo-contact-form .row {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 0 20px;
}
  section#logo-contact-form .col {
    width: 100%;
}
  section#aboutus {
    text-align: center;
}
  .calltoactions {
    justify-content: center;
}
  section#discover .row {
    display: flex;
    flex-wrap: wrap-reverse;
    text-align: center;
}
  .footer-nav-links ul li span {
    text-align: left;
}
  .about .social ul {
    justify-content: center;
    margin-bottom: 20px;
}
  section#footer button {
    float: unset;
}
section#footer .col h1 {
  font-size: 25px;
  line-height: 35px;
}

section#footer .col {
    width: 100%;
    text-align: center;
}
  section#aboutus p {
    font-size: 12px;
    line-height: 22px;
}

section#aboutus h3 {
    font-size: 16px;
    line-height: 25px;
}
section#aboutus h1 {
    font-size: 35px;
    line-height: 45px;
}
  section#aboutus .col img {
    width: 100%;
}

section#aboutus .col {
    width: 100%;
}
  section#about-banner .about-text {
    padding: 20px 20px;
  }
  .about-text p br {
    display: none;
}

.about-text p {
    font-size: 12px;
    line-height: 22px;
}
  section#about-banner h1 br {
    display: none;
}

section#about-banner h1 {
    font-size: 25px;
    line-height: 35px;
}
section#about-banner {
  padding: 100px 0 0px 0px !important;
}
  .navbar-nav.navbar-nav-scroll {
    height: auto !important;
    max-height: unset !important;
}
  div#navbarScroll {
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    background: #000000d1;
    top: 0;
    bottom: 0;
    z-index: 111111;
}
  .header nav.navbar.navbar-expand-lg.navbar-light {
    float: right;
}
  .navbar-light .navbar-toggler-icon {
    filter: brightness(0)invert(1);
}
  section#bringideas .tabs-componentbutton {
    margin-top: 30px;
}
  section#discover p {
    font-size: 12px;
    line-height: 22px;
}
section#discover h1 {
    font-size: 25px;
    line-height: 25px;
}
  section#bringideas .tabs ul li {
    font-size: 12px;
    margin-bottom: 20px;
}
section#bringideas .tabs ul {
    display: block;
    column-count: 3;
}
  section#bringideas .tab-content {
    width: 100%;
}
  section#discover img {
    width: 100%;
}
  section#bringideas .col-5 .headings h1 {
    font-size: 35px;
    line-height: 45px;
}
  section#bringideas .col {
    width: 100%;
}
  section#products-ideas .products p {
    font-size: 12px;
    line-height: 22px;
}

section#products-ideas .products h2 {
    font-size: 20px;
    line-height: 30px;
}
  section#products-ideas .products {
    padding: 20px;
}
  section#products-ideas .products img {
    margin-bottom: 30px;
    width: 20%;
}
section#products-ideas .row {
  margin: 0;
  padding: 0 !important;
}

section#products-ideas .row .col {
  width: 100%;
  margin-bottom: 20px;
}
  section#products-ideas .row a.view {
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
}
  section#products-ideas h1 {
    font-size: 35px;
    line-height: 45px;
}

section#products-ideas h3 {
    font-size: 20px;
    line-height: 30px;
}
  section#ourteams .row {
    display: flex;
    flex-wrap: wrap-reverse;
    text-align: center;
}
  section#ourteams button.next {
    right: 10%;
}

section#ourteams button.previous {
    left: 10px;
}
  section#ourteams .flickity-slider {
    left: 0 !important;
}
  section#ourteams .heading-text h5 {
    font-size: 13px;
    line-height: 23px;
}

section#ourteams .heading-text p {
    font-size: 20px;
    line-height: 30px;
}
section#ourteams .heading-text h1 {
    font-size: 35px;
    line-height: 45px;
}
section#ourteams .heading-text {
    position: unset;
    width: 100%;
}
  section#featurework .image-hover:hover .content {
    top: 60px;
}
  section#featurework .image-hover:hover .content p {
    font-size: 12px;
    line-height: 22px;
}

section#featurework .image-hover:hover .content h2 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
}
  section#featurework .image-hover .content {
    right: unset !important;
    padding: 20px 20px;
    left: 0 !important;
}
  section#featurework .col {
    width: 100%;
}
  section#featurework h1 {
    font-size: 35px;
    line-height: 45px;
}
  section#projects img {
    margin: 20px 0;
}
  section#projects .row .col img {
    width: 100%;
}
section#projects .row .col {
    width: 50%;
}
  section#projects h1 {
    font-size: 35px;
    line-height: 45px;
}
  section#banner h1.animated-text span {
    left: 0;
    right: 0;
    margin: 0 auto;
}
  
section#banner .second-row button, section#featurework button, section#footer button, section#aboutus button {
    text-align: center;
    margin: 0 auto;
    display: table;
    padding: 8px 38px;
    border: 2px solid;
    font-size: 13px;
}
  section#banner .second-row p {
    text-align: center;
    font-size: 12px;
    line-height: 22px;
}
  section#banner .second-row h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
}
  section#banner .second-row .col {
    width: 100%;
}
  section#banner .second-row {
    padding: 20px 0px 50px 0px;
}
section#banner h1 {
  font-size: 38px;
  line-height: 48px;
  text-align: center;
}
  section#banner h1.animated-text span {
    font-size: 46px;
    line-height: 56px;
}
  section#banner h1.animated-text button {
    padding: 0px 10px;
    line-height: 30px;
    top: -9px;
    font-size: 11px;
}
  section#banner {
    padding: 120px 0 0px 0;
}
  body {
    overflow-x: hidden;
}
}

@media (max-width: 414px) {}

@media (max-width: 375px) {}

@media (max-width: 360px) {}
section#products-ideas a.view {
  text-align: center;
  color: #198754;
  font-size: 20px;
  font-weight: bold;
}
#aboutus {
  padding: 50px 0px;
}
section#about-banner {
  text-align: center;
  padding: 160px 0;
  background: #000;
}
section#about-banner h1 span {
  color: #51a599;
  font-weight: 100;
}
.about-text {
  border-top: 5px solid #4ea197;
  border-bottom: 5px solid #4ea197;
  padding: 20px 0;
}
/* CONTACT US */
section#logo-contact-form .heading-text h1 {
  font-size: 34px;
  line-height: 44px;
}
section#logo-contact-form .about-banner-buttons ul {
  text-align: left;
  margin: 0;
  padding: 0;
}

.service-logosection .first-inner-section-text p b {
  color: #6ab371;
}
section#logo-contact-form .logo-conactfomrinner input, section#logo-contact-form .logo-conactfomrinner textarea {
  border: 1px solid #BCBCBC66 !important;
  background: #212621;
  color: #fff;
  margin-bottom: 20px;
  padding: 13px 10px;
  width: 100%;
  font-style: inherit;
  border-radius: 10px;
}
section#logo-contact-form .logo-conactfomrinner {
  border: 1px solid #ccc;
  box-shadow: 0px 12px 15px 0px #0000001A;
  padding: 50px 30px;
  border-radius: 40px;
  background: #0f2824;
}
section#logo-contact-form .logo-conactfomrinner form#contact {
  padding: 0;
}
section#logo-contact-form button#contact-submit {
  width: 100%;
  background: #101715;
  color: #fff;
  border-radius: 20px;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 400;
}
section#logo-contact-form ::placeholder {
  color: #fff !important;
  font-style: inherit !important;
  font-weight: 100;
}
.logo-cta .col-6 {
  position: relative;
}

section#logo-contact-form .contact-inner .col-6 {
  padding: 0 50px;
}
section#logo-contact-form .about-banner-buttons ul li a {
  color: #1ef4c0;
  font-size: 22px;
  text-decoration: unset;
  border-bottom: 1px solid;
}

section#logo-contact-form .about-banner-buttons ul {
  display: flex;
  gap: 60px;
  margin: 0;
  padding: 0;
}
section#logo-contact-form .heading-text h1 span {
  color: #4ea197;
  font-weight: 100;
}
section#logo-contact-form .contact-inner form textarea {
  height: 130px;
  resize: unset;
}

section#logo-contact-form .contact-inner form input, section#logo-contact-form .contact-inner form textarea {
  width: 100%;
  max-width: 100%;
  padding: 11px 20px;
  background: #1c1a1a;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}
section#logo-contact-form .contact-inner form label {
  display: table;
  width: 100%;
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
}
section#logo-contact-form .contact-inner form {
  border: 1px solid #3a3636;
  padding: 20px 20px;
  background: #102925;
  border-radius: 30px;
}
section#banner .second-row a {
  text-decoration: unset;
}
section#featurework a, section#footer a, section#aboutus a {
  text-decoration: unset;
}

section#banner .second-row h2 span, #jobpositions h1 span {
  font-weight: 100;
  color: #1ef4c0;
}

section#banner .second-row h2 {
  text-transform: uppercase;
}
section#jobpositions .posting button {
  padding: 7px 21px;
  font-size: 12px;
  border: 2px solid;
}
section#jobpositions .posting {
  text-align: left;
  border: 1px solid #ccc;
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 11px 11px 11px #1ef4c073;
  animation: pulse 2s infinite;
}

/* Popup */
.details-popup .details-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: table;
  background: #0d231f;
  width: 70%;
  top: 3%;
  padding: 10px 40px;
  border-radius: 20px;
  border: 2px solid #ccc;
}
.jobdetails h6 span {
  color: #fff;
  width: 70%;
  float: right;
}

.jobdetails h6 {
  color: #62ffb6;
  padding-bottom: 6px;
  font-size: 12px;
}
.details-popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999;
  top: 0;
  background: #000000d1;
}
.details-popup .details-content h5 b {
  font-weight: bold;
}

.details-popup .details-content h5 {
  font-size: 13px;
  margin-bottom: 20px;
  font-weight: 100;
}
.details-popup .details-content ul li {
  font-size: 13px;
  line-height: 23px;
}
.details-popup .details-content p {
  font-size: 13px;
  line-height: 23px;
}
.details-popup .details-content h3 {
  font-size: 17px;
  line-height: 27px;
}
.details-popup .details-content h2 {
  font-size: 20px;
  line-height: 30px;
}
.applynow button {
  padding: 8px 41px;
  border: 2px solid #fff;
  color: #000;
  background: #fff;
  font-size: 14px;
}
.details-popup .details-content button.close img {
  width: 40px;
  opacity: 1;
  filter: brightness(0)invert(1);
}
.details-content ul {
  padding-left: 18px;
}
.details-popup .details-content button.close {
  box-shadow: unset !important;
  padding: 0 0;
  opacity: 1;
  position: absolute;
  right: -4px;
  top: -2px;
}
.details-popup {
  overflow-y: scroll;
}
section#footer h1 {
  text-transform: uppercase;
  font-weight: 500;
}
section#footer h1 span {
  font-weight: 100;
  color: #1ef4c0b5;
  text-transform: uppercase;
}